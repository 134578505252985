import * as React from "react";
import { Admin, Resource } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import {UserList,UserEdit, UserCreate} from './components/users/users';
import {DIDList, DIDEdit, DIDCreate} from './components/did/dids';
import {ProviderList, ProviderEdit, ProviderCreate} from './components/provider/providers';
import {MxSystemList, MxSystemEdit, MxSystemCreate}  from './components/mxsystem/mxsystems';
import {MMSProviderList, MMSProviderEdit, MMSProviderCreate}  from './components/mmsprovider/mmsproviders';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import smsBaseProvider from './smsBaseProvider'
import MyLoginPage from './MyLoginPage';
// import postList from './userbook.js';
import { sizing } from '@mui/system';
import Box from '@mui/material/Box';
 //const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const App = () => {
  console.log('test app')
  // if (!authProvider || !smsBaseProvider) return null;
  return (


        <Admin loginPage={MyLoginPage} title={"my page title"} disableTelemetry authProvider={authProvider}
               dashboard={Dashboard} dataProvider={smsBaseProvider}>
          <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate}/>
          <Resource name="dids" list={DIDList} edit={DIDEdit} create={DIDCreate}/>
          <Resource name="systems" options={{ label: 'MX Systems' }}  list={MxSystemList} edit={MxSystemEdit} create={MxSystemCreate}/>
          <Resource name="providers" list={ProviderList} edit={ProviderEdit} create={ProviderCreate}/>
          <Resource name="mmsproviders" options={{ label: 'MMS Providers' }} list={MMSProviderList} edit={MMSProviderEdit} create={MMSProviderCreate}/>
          {/*<Resource name="posts" list={ListGuesser} create={ListGuesser} />*/}
        </Admin>

  );
}
export default App;
