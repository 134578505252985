import React from 'react'
import { List, BooleanField, Datagrid, TextField, ReferenceField,DateField, EditButton,
   Edit, SimpleForm,ReferenceInput,SelectArrayInput,ReferenceManyField,ChipField,SingleFieldList,
  ReferenceArrayInput, SelectInput,required, NumberInput,
   DateInput, BooleanInput,DeleteButton,Toolbar,
  SaveButton,
  TextInput,Create,useNotify } from 'react-admin'
import { useRecordContext, Show, SimpleShowLayout } from 'react-admin';

const CustomBulkActionButtons = (props) => (
  <React.Fragment>
    {/* Add any other custom bulk actions here if needed */}
  </React.Fragment>
);
const validateRequired = required('This field is required');


export const DIDList = () => {
  return <List title="List of DIDS" pagination={false}>
    <Datagrid bulkActionButtons={<CustomBulkActionButtons />}>
      <TextField sortable={false} source="id"/>
      {/*<TextField source="systemId"/>*/}
      <ReferenceField sortable={false} label="System" source="systemId"  reference="systems" >
        <TextField source="systemName"  emptyText="missing data"  />
      </ReferenceField>
      {/*<ReferenceField label="System" source="systemId" reference="mxsystems">*/}
        {/*<TextField source="systemName" />*/}
      {/*</ReferenceField>*/}
      <ReferenceField sortable={false} label="Provider" source="providerId" reference="providers" >
        <TextField source="hostName" />
      </ReferenceField>
      {/*<ReferenceManyField label="Comments by" reference="providers" target="providerId">*/}
        {/*<SingleFieldList>*/}
          {/*<ChipField source="hostName" />*/}
        {/*</SingleFieldList>*/}
      {/*</ReferenceManyField>*/}
      {/*<TextField source="providerId"/>*/}
      <TextField sortable={false} source="did" label="DID" />
      <BooleanField sortable={false} source="isTemporary"  label="Is Temporary" />
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

const CustomToolbar = props => {
  const record = useRecordContext();
  console.log(record)
  if(record.protected){

    return (
      <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <SaveButton />
        <DeleteButton className='protected-class' />
      </Toolbar>
    );
  } else {
    return (
      <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <SaveButton />
        <DeleteButton />
      </Toolbar>
      )
  }
}

export const DIDEdit= () => {
  return (
    <Edit title='Edit a DID'>
      <SimpleForm>
        <TextInput disabled source='id'/>
        {/* <ReferenceInput label="System"  perPage={100} source="systemId"  reference="systems"   >
          <SelectInput  optionValue="id" emptyValue={0} optionText="systemName" />
        </ReferenceInput>
        <ReferenceInput  label="Provider" source="providerId" reference="providers"  allowEmpty>
          <SelectInput emptyValue={0} optionText="hostName" optionValue="id"/>
        </ReferenceInput> */}
        {/*<NumberInput source="providerId"/>*/}
        {/*<SelectInput source="providerId" reference="SMPP"  />*/}
        <TextInput source="did"  label="DID" />
        <BooleanInput source="isTemporary"  label="Is Temporary" />
      </SimpleForm>
    </Edit>
  )
}

export const DIDCreate = () => {
  return (
    <Create title='Create a DID'>
      <SimpleForm>
        {/* <ReferenceInput label="System" perPage={100} source="systemId" reference="systems">
          <SelectInput optionValue="id" emptyValue={0} optionText="systemName" validate={validateRequired} />
        </ReferenceInput>
        <ReferenceInput label="Provider" source="providerId" reference="providers">
          <SelectInput optionText="hostName" optionValue="id" validate={validateRequired} />
        </ReferenceInput> */}
        <TextInput source="did" label="DID" validate={validateRequired} />
        <BooleanInput source="isTemporary" label="Is Temporary"  />
      </SimpleForm>
    </Create>
  );
};

